import type { ComponentType } from "react"

export function withFourLineEllipsis(Component): ComponentType {
    return (props) => {
        if (typeof window === "undefined") {
            return <Component {...props} />
        }

        const newStyle = {
            ...props.style,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 4, // 4 lines
            textOverflow: "ellipsis",
        }
        return <Component {...props} style={newStyle} />
    }
}
